<template>
  <div>
    <history></history>
  </div>
</template>
<script>
import history from "../components/history.vue";
export default {
  data() {
    return {};
  },
  components: {
    history,
  },
  props: {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    check(data) {
      console.log("Console log : check -> data", data);
    },
  },
};
</script>
