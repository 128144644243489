<template>
  <div>
    <div class="columns">
      <div class="column center">
        <div class=" card-container feedback">
          <!-- <h3>What rate would you give to Qbrics Flows?</h3>
          <div class="feedback_ratings">
            <span
              v-for="rate in rates"
              :key="rate.rate"
              :class="[
                'feedback_rating',
                { rating_active: rate.rate === selectedRate },
              ]"
              @click="selectRating(rate.label)"
              :data-rate="rate.rate"
            >
              {{ rate.emoji }} {{ rate.label }}
            </span>
          </div>
          <div class="feedback-n" v-html="noticeHTML"></div> -->
          <h3>What rate would you give to Qbrics Flows?</h3>
          <div class="stars center-imp" @mouseleave="resetHoveredRating()">
      <span v-for="star in 5" :key="star" @mouseover="setHoveredRating(star)" @click="setRating(star)">
        <i class="fa fa-star" :class="[getStarClass(star),getHoverStarClass(star)]"></i>
      </span>
    </div>
    <!-- <div class="rating-category">{{ selectedCategory }}</div> -->
    <div class="feedback-n" v-html="noticeHTML"></div>
        </div>
      </div>
    </div>
    <div class="columns">
      
      <div class="column">
        <div class="card-container box-rounded">
          <div class="columns">
            <div class="column">
              <div style="" class="">
                
                <div class="field is-horizontal">
  
  <div class="field-body">
    <div class="field">
      <p class="control">
        <div class="select qbselect">
                    <select v-model="category">
                      <option v-for="c in categories" :key="c">{{ c }}</option>
                    </select>
                  </div>
      </p>
    </div>
  </div>
</div>
               
                <justinput icon="token.svg" v-model="feedbackSuj" lableName="Subject" @input="handleInput" ></justinput>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Comment</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    v-model="comment"
                    style="height: 194px; border-radius: 20px;"
                    placeholder="Comment"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <nav class="card-container level">
                <!-- Left side -->
                <div class="level-left"></div>

                <!-- Right side -->
                <div class="level-right">
                  <div class="level-item">
                    <div class="field">
                      <button @click="submitFeedback" class="button is-lightblue is-rounded">
                        <span>Submit</span>
                        <b-icon
                          v-if="submitting"
                          size="is-small"
                          pack="fas"
                          icon="circle-notch"
                          custom-class="fa-spin"
                        >
                        </b-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoint } from "../services/global-constant";
export default {
  data() {
    return {
      category: "User Experience",
      subject: "",
      comment: "",
      categories: ["User Experience", "Bug Report", "Feature Request", "Other"],
      submitting: false,
      selectedRate: null,
      selectedCategory: '',
      selectRating: 0,
      hoveredCategory: '',
      hoveredRating: 0,
      rates: [
        { rate: 1, emoji: "😢", label: "Worst" },
        { rate: 2, emoji: "😒", label: "Poor" },
        { rate: 3, emoji: "😐", label: "OK" },
        { rate: 4, emoji: "😏", label: "Good" },
        { rate: 5, emoji: "😁", label: "Great" },
      ],
      feedbackSuj : '',
    };
  },
  mounted() {
    
  },
  components : {
    justinput: () => import("../components/justInput.vue"),
  },
  computed: {
    noticeHTML() {
      if( this.selectRating == 0 || this.selectedCategory == '' ){
        return `<section class="feedback_notice">
                  <p>Please select the feedback</p>
                </section>`;
      }
      if (this.selectRating <= 2) {
        return `<section class="feedback_notice">
                  <p>Sorry to hear that ${this.selectedCategory}! Thank you for the feedback.</p>
                </section>`;
      } else {
        return `<section class="feedback_notice">
                  <p>${this.selectedCategory}! Thank you.</p>
                </section>`;
      }
    },
  },
  methods: {
    submitFeedback: async function () {
      console.log("category ", this.category);
      this.submitting = true;
      var feedbackBody = {
        category: this.category,
        subject: this.subject,
        comment: this.comment,
      };

      var feedback = await this.$store.getters.authFlowObject.postReq(
        endpoint.USER_FEEDBACK,
        feedbackBody,
        null
      );
      console.log("Console log : init -> feedback", feedback);
      this.category = "Bug Report";
      this.subject = "";
      this.comment = "";
      this.submitting = false;
    },
    selectRatingOld(rate) {
      this.selectedRate = rate;
    },
    setRating(star) {
      switch (star) {
        case 1:
          this.selectedCategory = 'Poor';
          this.selectRating = 1
          break;
        case 2:
          this.selectedCategory = 'Below Average';
          this.selectRating = 2
          break;
        case 3:
          this.selectedCategory = 'Average';
          this.selectRating = 3
          break;
        case 4:
          this.selectedCategory = 'Good';
          this.selectRating = 4
          break;
        case 5:
          this.selectedCategory = 'Excellent';
          this.selectRating = 5
          break;
      }
    },
    resetRating() {
      this.selectedCategory = '';
      this.selectRating = 0;
    },
    setHoveredRating(star) {
      switch (star) {
        case 1:
          this.hoveredCategory = 'Poor';
          this.hoveredRating = 1
          break;
        case 2:
          this.hoveredCategory = 'Below Average';
          this.hoveredRating = 2
          break;
        case 3:
          this.hoveredCategory = 'Average';
          this.hoveredRating = 3
          break;
        case 4:
          this.hoveredCategory = 'Good';
          this.hoveredRating = 4
          break;
        case 5:
          this.hoveredCategory = 'Excellent';
          this.hoveredRating = 5
          break;
      }
    },
    resetHoveredRating() {
      this.hoveredCategory = '';
      this.hoveredRating = 0;
    },
    getStarClass(star) {
      return star <= this.selectRating ? 'filled' : '';

    },
    getHoverStarClass(star) {
      return star <= this.hoveredRating ? 'filled' : '';

    },
    handleInput(inputvalue){
      this.feedbackSuj = inputvalue
    }
  },
};
</script>

<style lang="scss" scoped>
.stars {
  font-size: 24px;
    gap: 10px;
    display: flex;
    margin-top: 1rem;
}

.fa-star {
  color: gray;
  cursor: pointer;
}

.filled {
  color: gold;
}
.rating-category {
  font-size: 18px;
  margin-top: 10px;
}
.feedback {
    transition: height 2s ease-in;
    padding: 15px;
    height: auto;
    // width: 100%;
    /* max-width: 450px; */
    /* min-width: 450px; */
    overflow: hidden;
    background-color: rgba(18, 19, 156, 0.7);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;


  h3 {
    margin-top: 0;
    font-weight: 700;
    font-size: 1.3rem;
  }
  &_ratings {
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
    gap: 18px;
    margin-top: 10px;

  }
  &_rating {
    cursor: pointer;
    padding: 6px 10px;
    background-color: #e3e9ed;
    border-radius: 100px;
    color: #000;
  }
  &-n {
    margin-top: 1rem;
    transition: all 200ms ease-in-out;
  }
  &_notice {
    color: #000;
    transition: all 200ms ease-in-out;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    p {
      -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      margin: 0;
    }
  }
}
.rating_active {
  background-color: #000;
  color: #e3e9ed;
  transition: all 200ms ease-in-out;
}
.active_feedback {
  height: 240px;
  transition: height 0.6s ease-in;
}
.hidden {
  display: none;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cus-field-lable{
  flex-basis: 0; 
    flex-grow: 0;
    /* flex-shrink: 0; */
    margin-right: 1.5rem;
    /* text-align: right; */
    display: flex;
}

.qbselect select:not([multiple]) {
    padding-right: 2.5em;
    border-radius: 14px;
}
</style>
