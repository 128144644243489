<template>
  <div class="main-profile-container" style="padding: 30px">
    <div class="box-rounded-no-padding" style="width: 35%;">
      <div class="container">
        <div class="">
          <div class="">
            <div class="">
              <div class="profile-sd">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-96x96">
                      <avatar :lighten="60" :size="96" :username="fullname" :src="userPhoto"></avatar>
                    </figure>
                  </div>
                  <div class="media-content">
                    <div v-if="noData">
                      <b-skeleton width="40%" :animated="animated"></b-skeleton>

                      <b-skeleton width="20%" :animated="animated"></b-skeleton>
                    </div>
                    <div v-else>
                      <p class="title is-4 has-text-white">
                        {{ fullname
                        }}<span class="subtitle is-6 has-text-white">
                          ({{ email }})</span>
                      </p>
                      <p class="subtitle is-6 has-text-white">
                        {{ designation }} @ {{ org }}
                      </p>
                    </div>
                  </div>
                  <div class="media-right profile-edit-position">
                    <!-- <div
                class="dropdown is-right"
                @click="showDropdown = !showDropdown"
                :class="{ 'is-active': showDropdown }"
              >
                <div class="dropdown-trigger">
                  <b-icon
                    custom-class="center-imp iconh"
                    icon="dots-vertical"
                    size=""
                  >
                  </b-icon>
                </div>
                <div class="dropdown-menu" id="dropdown-menu6" role="menu">
                  <div class="dropdown-content">
                    <div class="dropdown-item">
                      <a
                        href="#"
                        @click="toggleEdit"
                        class="dropdown-item"
                      >
                        {{editText}}
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->
                    <b-tooltip label="Click to edit your profile." type="is-dark" position="is-left">
                      <a @click="toggleEdit" role="button" style="color: white; margin-left: 4px;">
                        <i class="fas fa-user-edit"></i>
                      </a>
                    </b-tooltip>
                  </div>
                </div>

                <div class="content"></div>
              </div>
            </div>
            <br />
            <div class="" style="padding: 20px">
              <div class="columns">
                <div class="column is-2 ">
                  <!-- <div style="" class="">

                  <div class="field">
                    <label class="label">Full Name</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="fullname"
                        id="profile-fullname"
                        placeholder="Name"
                        :disabled="isEditable"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Designation</label>
                    <div class="control">
                      <input
                        class="input"
                        v-model="designation"
                        type="text"
                        id="profile-designation"
                        placeholder="Salutation"
                        :disabled="isEditable"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Phone</label>
                    <div class="control">
                      <input
                        class="input"
                        type="tel"
                        v-model="phone"
                        id="profile-phone"
                        placeholder="Phone"
                        :disabled="isEditable"
                      />
                    </div>
                  </div>
                </div> -->
                  <div class="icon-profile-details">
                    <span class="icon-text">
                      <span class="icon">
                        <img src="../assets/profilecard.png">
                      </span>
                    </span>
                    <span class="icon-text">
                      <span class="icon">
                        <img src="../assets/mailcheck.png">
                      </span>
                    </span>
                    <span class="icon-text">
                      <span class="icon">
                        <img src="../assets/designation.png">
                      </span>
                    </span>
                    <span class="icon-text">
                      <span class="icon">
                        <img src="../assets/phone.png">
                      </span>
                    </span>
                    <span class="icon-text">
                      <span class="icon">
                        <img src="../assets/location.png">
                      </span>
                    </span>
                  </div>
                </div>
                <div class="column">
                  <!-- <div class="field">
                    <label class="label">Address</label>
                    <div class="control">
                      <textarea
                        class="textarea"
                        v-model="address"
                        style="height: 174px"
                        placeholder="Address"
                        :disabled="isEditable"
                      ></textarea>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Wallet Balance</label>
                    <div class="control">
                      <input
                        class="input"
                        type="number"
                        v-model="balance"
                        id="profile-balance"
                        placeholder="0"
                        disabled
                      />
                    </div>
                  </div> -->
                  <div class="profile-details">
                    <span>
                      {{ fullname }}
                    </span>
                    <span>
                      {{ email }}
                    </span>
                    <span>
                      {{ designation }}
                    </span>
                    <span>
                      {{ phone }}
                    </span>
                    <span>
                      {{ address || 'NA' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="colums">
              <div class="column">
                <div class="field is-grouped is-grouped-right">
                  <div class="control">
                    <button
                      id="submit-profile"
                      @click="updateProfile"
                      class="button is-link"
                      :disabled="isEditable"
                    >
                      <span>Update</span>
                      <b-icon
                        v-if="updating"
                        size="is-small"
                        pack="fas"
                        icon="circle-notch"
                        custom-class="fa-spin"
                      >
                      </b-icon>
                    </button>
                  </div>
                  <div class="control">
                    <button
                      id="notification"
                      @click="clearAll"
                      class="button is-link is-light"
                      :disabled="isEditable"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

    </div>
    <div class="grid-containter">
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/dollar.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ userBalance | usNumberFormat }}</div>
                <div class="has-text-grey">Wallet Balance</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/orgdollar.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ orgBalance | usNumberFormat }}</div>
                <div class="has-text-grey">{{ org }} Balance</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/uspent.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ userSpent | usNumberFormat }}</div>
                <div class="has-text-grey">Spent By You</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/ospent.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ orgSpent | usNumberFormat }}</div>
                <div class="has-text-grey">Spent By {{ org }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/token.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ tokenCount | usNumberFormat }}</div>
                <div class="has-text-grey">Available Tokens</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/owner.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ tokenOwned | usNumberFormat }}</div>
                <div class="has-text-grey">Tokens Owned</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/shared.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ tokenSharedWithMe | usNumberFormat }}</div>
                <div class="has-text-grey">Tokens Shared With Me</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/meshare.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ tokenSharedByMe | usNumberFormat }}</div>
                <div class="has-text-grey">Tokens Shared By Me</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stat-card">
        <div class="box-rounded">
          <div class="columns">
            <div class="column center-imp">
              <span class="">
                <span class="icon" style="height: 60px; width: 60px;">
                  <img src="../assets/icon_svg/othshare.svg">
                </span>
              </span>
            </div>
            <div class="column">
              <div style="width: max-content;">
                <div class="" style="    font-size: 2rem;">{{ tokenSharedByOthers | usNumberFormat }}</div>
                <div class="has-text-grey">Tokens Shared By Others</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal :active.sync="doUpdate">
      <profile-sign :toUpdate="true" :userData="allUserData" :signatureData="currentSignatureData"
        @close-update-profile="init($event)"></profile-sign>
    </b-modal>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import { endpoint } from "../services/global-constant";
export default {
  data() {
    return {
      showDropdown: false,
      isEditable: true,
      doUpdate: false,
      animated: true,
      noData: true,
      fullname: "",
      designation: "",
      phone: "",
      address: "",
      updating: false,
      editText: "Edit",
      api: null,
      email: "",
      org: "",
      userBalance: 0,
      allUserData: null,
      currentSignatureData: null,
      userPhoto: "",
      tokenCount: 0,
      tokenOwned: 0,
      tokenSharedWithMe: 0,
      tokenSharedByMe: 0,
      tokenSharedByOthers: 0,
      orgBalance: 0,
      userSpent: 0,
      orgSpent: 0,
    };
  },
  components: {
    profileSign: () => import("../components/profileSign.vue"),
    Avatar,
  },
  props: {},
  async mounted() {
    await this.init();
    this.userPhoto = localStorage.getItem("userPhoto");
    this.$root.$on('user-photo', this.updatePhotoHandler);
    this.$root.$on("profile_refresh", this.getProfile);
    console.log("userphoto from local storage:", this.userPhoto);
  },
  computed: {
    profileRefreshEvent() {
      return this.$store.getters.profileRefreshEvent;
    },
  },
  watch: {
    profileRefreshEvent: {
      handler(newValue, oldValue) {
        console.log("profile refresh: ", newValue, oldValue);
        this.getProfile();
      }
    },
  },
  methods: {
    async init(event) {
      console.log("on close event", event)
      if (event) {
        this.doUpdate = false
      }
      this.getProfile();
    },
    getProfile: async function () {
      var profile = await this.$store.getters.authFlowObject.getReq(
        endpoint.USER_PROFILE,
        null
      );
      this.fullname = profile.data.full_name;
      this.designation = profile.data.designation;
      this.phone = profile.data.phone;
      this.address = profile.data.address;
      this.email = profile.data.email;
      this.userBalance = profile.data.coin_stats.user_balance;
      this.orgBalance = profile.data.coin_stats.org_balance;
      this.userSpent = profile.data.coin_stats.user_spent;
      this.orgSpent = profile.data.coin_stats.org_spent;
      this.tokenCount = profile.data.token_stats.count;
      this.tokenOwned = profile.data.token_stats.owned;
      this.tokenSharedWithMe = profile.data.token_stats.shared_with_me;
      this.tokenSharedByMe = profile.data.token_stats.shared_by_me;
      this.tokenSharedByOthers = profile.data.token_stats.sharerd_by_others;
      this.org = profile.data.org[0].toUpperCase() + profile.data.org.slice(1);
      this.noData = false;
      console.log("Console log : init -> profile", profile);
      this.allUserData = profile;

      var setWetSign = await this.$store.getters.authFlowObject.getReq(
        endpoint.USER_WET_SIGN,
        null
      );

      this.currentSignatureData = setWetSign;
      console.log("Console log : init -> setWetSign", setWetSign);
    },
    updateProfile: async function () {
      this.updating = true;
      var setProfileData = {
        full_name: this.fullname,
        phone: this.phone,
        address: this.address,
        designation: this.designation,
      };
      var setProfile = await this.$store.getters.authFlowObject.postReq(
        endpoint.USER_PROFILE,
        setProfileData,
        null
      );
      console.log("Console log : setProfile", setProfile);
      this.updating = false;
    },
    clearAll() {
      this.fullname = "";
      this.designation = "";
      this.phone = "";
      this.address = "";
    },
    toggleEdit() {
      this.isEditable = !this.isEditable;
      this.doUpdate = !this.doUpdate;
      if (this.isEditable) {
        this.editText = "Edit";
      } else {
        this.editText = "Done";
      }
    },
    updatePhotoHandler() {
      console.log('updating user photo');
      this.userPhoto = localStorage.getItem("userPhoto");
    },
  },
};
</script>
