<template>
  <div class="top-container">
    <img class="home-background" src="../../public/1024x1024_sq_logo_grey1.png" />
    <div class="">
      <div class="" style="margin-bottom: 100px">
        <constgrid :alldata="cards" :noOfColumns="6" :cellWith="2" :cellHeight="4"></constgrid>
      </div>
      <b-modal v-model="showSharePopup" class="" @close="selectedCardid = '';">
        <div class="just-white rounded-box-container">
          <div class="just-box">
            <div class="popup-container">
              <form @submit.prevent="">
                <div class="">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">User(s)</label>
                    </div>
                    <div class="field-body">
                      <div class="field">
                        <div class="control">
                          <input id="input-field" class="input is-rounded" v-model="userString" />
                          <span class="button-in-input is-small is-right">
                            <button class="button is-rounded is-small" @click="addToArr()">
                              Add
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label"></label>
                    </div>
                    <div class="field-body">
                      <div class="field just-box-rounded">
                        <div class="more-rounded-box control">
                          <div class="tagsfield field is-grouped is-grouped-multiline">
                            <div>
                              <Chips class="just-box-rounded" v-model="users" separator="," /> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div></div>
          </div>
          <div class="just-box just-shadow-top just-box-rounded-bottom">
            <div class="center">
              <div>
                <button @click="closeSharePopup()" class="button is-rounded is-yellow">
                  <span class=""> Share </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal v-model="showRecipientsPopup" class="">
        <div class="just-white rounded-box-container">
          <div class="just-box">
            <div class="popup-container-small">
              <form @submit.prevent="">
                <div class="">
                  <div class="field is-horizontal">
                    <div class="field-label is-normal">
                      <label class="label">Recipients :</label>
                    </div>
                    <div class="field-body">
                      <div class="field just-box-rounded">
                        <div class="more-rounded-box control">
                          <div class="tagsfield field is-grouped is-grouped-multiline">
                            <div>
                              <b-taginput v-model="recipients" :closable="false" :readonly="true" type="is-info"
                                size="is-medium" rounded></b-taginput>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Chips from "primevue/chips";
export default {
  data() {
    return {
      showSharePopup: false,
      showRecipientsPopup: false,
      userString: "",
      users: [],
      recipients: [],
      selectedCardid: "",
    };
  },
  components: {
    constgrid: () => import("../components/walletGrid.vue"),
    Chips,
  },
  props: {},
  mounted: async function () {
    this.$root.$on("select_token", this.selectToken);
    this.$root.$on("burn_token", this.burnToken);
    this.$root.$on("download_token", this.downloadToken);
    this.$root.$on("token_recipients", this.tokenRecipients);
    this.$root.$on("token_certificate", this.downloadCertificate);
    await this.$store.dispatch("FETCH_TOKENS");
  },
  beforeDestroy: function () {
    this.$root.$off("select_token", this.selectToken);
    this.$root.$off("burn_token", this.burnToken);
    this.$root.$off("download_token", this.downloadToken);
    this.$root.$off("token_recipients", this.tokenRecipients);
    this.$root.$off("token_certificate", this.downloadCertificate);
    console.log("removed event listeners");
  },
  computed: {
    cards: function () {
      return this.$store.getters.userTokens;
    },
  },
  watch: {},
  methods: {
    async closeSharePopup() {
      const recipient = this.users.join(",");
      this.$store.commit("isLoading", true);
      const resp = await axios.post(
        "/api/token/" + this.selectedCardid + "/share",
        {
          recipient: recipient,
        }
      );
      console.log("share resp:", resp);
      this.userString = "";
      this.users = [];
      this.selectedCardid = "";
      this.showSharePopup = false;
      this.$store.commit("isLoading", false);
    },
    async addToArr() {
      const values = this.userString.split(",");

      await values.forEach((value, key) => {
        const trimmedValue = value.trim();
        if (trimmedValue !== "") {
          this.users.push(trimmedValue);
        }
      });

      this.users = [...new Set(this.users)];
      console.log("users", this.users);
    },
    async selectToken(data) {
      console.log("select_token recvd:", data);
      this.selectedCardid = data;
      this.showSharePopup = true;
    },
    async downloadToken(data) {
      if (data == this.selectedCardid) {
        return;
      }
      this.selectedCardid = data;
      this.$store.commit("isLoading", true);
      const resp = await axios.get(
        "/api/artifact/" + data + "?t=" + new Date().getTime(),
        {
          responseType: "blob",
        }
      );
      console.log("artifact resp:", resp);
      const blob = new Blob([resp.data], { type: resp.data.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = resp.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      this.selectedCardid = "";
      this.$store.commit("isLoading", false);
    },
    async burnToken(data) {
      console.log("burn token recvd:", data, this);
      if (data == this.selectedCardid) {
        return;
      }
      this.selectedCardid = data;
      this.$store.commit("isLoading", true);
      const resp = await axios.delete("/api/token/" + data);
      console.log("delete resp:", resp);
      this.$store.commit("isLoading", false);
      this.selectedCardid = "";
    },
    async tokenRecipients(data) {
      console.log("token recipients recvd:", data, this);
      if (data == this.selectedCardid) {
        return;
      }
      this.selectedCardid = data;
      this.$store.commit("isLoading", true);
      const resp = await axios.get("/api/token/" + data + "/recipients");
      console.log("recipients resp:", resp);
      this.$store.commit("isLoading", false);
      this.recipients = resp.data;
      this.showRecipientsPopup = true;
      this.selectedCardid = "";
    },
    async downloadCertificate(data) {
      if (data == this.selectedCardid) {
        return;
      }
      this.selectedCardid = data;
      this.$store.commit("isLoading", true);
      var resp;
      try {
        resp = await axios.get(
        "/api/token/" + data + "/certificate?t=" + new Date().getTime(),
        {
          responseType: "blob",
        }
      );  
      } catch (error) {
        console.log(error);
        this.selectedCardid = "";
        this.$store.commit("isLoading", false);
      }
      
      console.log("certificate resp:", resp);
      const blob = new Blob([resp.data], { type: resp.data.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = resp.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      this.selectedCardid = "";
      this.$store.commit("isLoading", false);
    },
  },
};
</script>
