<template>
  <div class="card-container">
    <div class="container is-fluid">
      <p class="subtitle is-5">Copyright &copy;&nbsp;Qbrics Inc 2022</p>
    </div>
    <br />
    <div class="container is-fluid">
      <p class="block">
        A simple, intuitive and flexible application to certify and verify
        documents, speed-up workflows and enhance transparency through
        innovative use of decentralized systems.
      </p>
      <div class="columns is-variable center-imp">
        <div class="column">
          <p class="has-text-left">
            <a>FAQ</a>, &nbsp; &nbsp; <a>Terms &amp; Conditions</a>, &nbsp;
            &nbsp;
            <a>Privacy Policy</a>
          </p>
        </div>
      </div>
      <hr />
      <div class="columns center-imp">
        <article class="message" style="width: 100%">
          <div class="message-header">
            <p style="">
              <span class="icon has-text-warning">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              Disclaimer
            </p>
          </div>
          <div class="message-body-default">
            <p class="block has-text-justified">
              Please note that this is an early alpha version of the Qbrics
              Flows software which is still undergoing development. The software
              and all content found on it are provided on an “as is” and “as
              available” basis. Qbrics Flows does not give any warranties,
              whether express or implied, as to the suitability or usability of
              the software, its website or any of its content.
            </p>

            <p class="block has-text-justified">
              Qbrics Flows will not be liable for any loss, whether such loss is
              direct, indirect, special or consequential, suffered by any party
              as a result of their use of the Qbrics Flows software or content.
              Any downloading or uploading of material to the application is
              done at the user’s own risk and the user will be solely
              responsible for any damage to any computer system or loss of data
              that results from such activities.
            </p>

            <p class="block has-text-justified">
              Should you encounter any bugs, glitches, lack of functionality or
              other problems on the application, please let us know immediately
              so we can rectify these accordingly. Your help in this regard is
              greatly appreciated! You can write to us at this address
              <a target="_blank" href="mailto:info@qbrics.com"
                >info@qbrics.com</a
              >
              .
            </p>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  methods: {
    init: async function () {},
  },
};
</script>
<style>
.message-body-default {
  border-color: #dbdbdb;
  border-radius: 4px;
  /* border-style: solid; */
  border-width: 0 0 0 4px;
  color: #4a4a4a;
  padding: 1.25em 1.5em;
}
</style>
